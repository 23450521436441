var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('legend',{staticClass:"h4 mb-40"},[_vm._v(" "+_vm._s(_vm.$t('registration.communication.title'))+" ")]),_c('div',{staticClass:"grid grid-cols-2 gap-4"},_vm._l((_vm.filteredCommunicationMethods),function(item,key){return _c('div',{key:key},[_c('input',{staticClass:"sr-only",attrs:{"id":item.code,"type":"radio","name":"communication-choice","disabled":item.isDisabled},domProps:{"value":item.code,"checked":_vm.value === item.code},on:{"input":function($event){return _vm.$emit('input', item.code)}}}),_c('label',{staticClass:"flex items-center p-10 mx-6 my-8 rounded cursor-pointer bg-grey-100 border-2",class:{
          'border-blue-500': _vm.value === item.code,
          'border-transparent': _vm.value !== item.code && !item.isDisabled,
          'text-grey-300 border-transparent cursor-not-allowed':
            item.isDisabled,
        },attrs:{"for":item.code}},[_c('Icon',{staticClass:"w-40 text-xl text-center rounded-full",class:{
            'text-blue-500': !item.isDisabled,
            'text-grey-300': item.isDisabled,
          },attrs:{"iconClass":`fas fa-${item.icon}`,"color":"bg-blue-500"}}),_c('h5',{staticClass:"pl-12 text-sm font-semibold"},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }