<template>
  <RegistrationLayout>
    <template v-slot:header>
      <HMButton
        color="white"
        rounded="full"
        size="x-small"
        fontWeight="semibold"
        textSize="small"
        padding="tight"
        @click="back"
      >
        <span>
          <Icon
            :iconClass="`fas ${isLastStep ? 'fa-times' : 'fa-chevron-left'}`"
          />
          {{ isLastStep ? $t('common.cancel_btn') : $t('common.back_btn') }}
        </span>
      </HMButton>
      <p v-if="step === 1" class="flex ml-auto text-black">
        {{ $t('registration.member_info.already_registered') }}
        <router-link
          class="ml-4 font-semibold"
          :to="{ name: 'login' }"
          v-text="$t('registration.member_info.sign_in')"
        />
      </p>
    </template>
    <template v-slot:default>
      <MemberInfo v-if="step === 1" @next="step = 2" />
      <ContactInfo v-if="step === 2" @next="step = 3" />
      <CommunicationPreferences v-if="step === 3" @next="step = 4" />
      <PasswordInfo v-if="step === 4" @next="step = 5" />
      <RewardsInfo v-if="step === 5" />
    </template>
  </RegistrationLayout>
</template>

<script>
import MemberInfo from './MemberInfo'
import ContactInfo from './ContactInfo'
import RewardsInfo from './RewardsInfo'
import CommunicationPreferences from './CommunicationPreferences'
import PasswordInfo from './PasswordInfo'
import RegistrationLayout from '@components/layouts/RegistrationLayout'

export default {
  components: {
    MemberInfo,
    RewardsInfo,
    ContactInfo,
    CommunicationPreferences,
    PasswordInfo,
    RegistrationLayout,
  },
  data() {
    return {
      step: 1,
    }
  },

  computed: {
    isLastStep() {
      return this.step === 5
    },
  },

  methods: {
    back() {
      if (this.step === 1) {
        this.$router.push({ name: 'login' })
      } else if (this.step === 4) {
        this.$router.replace('/')
      } else {
        this.step--
      }
    },
  },
}
</script>
