<template>
  <div :id="containerId" class="w-full">
    <div class="flex items-center">
      <Icon class="text-xl mr-16" :iconClass="`fas fa-${icon}`" />
      <h1 class="text-lg flex-1">{{ $t(title) }}</h1>
      <CustomSwitch :id="switchId" v-model="switchValue" />
    </div>
    <p class="mt-15 text-grey-600">
      {{ $t(body, [clientName]) }}
    </p>
  </div>
</template>

<script>
import CustomSwitch from '@components/common/CustomSwitch.vue'
import communicationToggle from '@platform-shared/mixins/communicationToggle.js'

export default {
  components: { CustomSwitch },
  mixins: [communicationToggle],
  computed: {
    containerId() {
      return `${this.code.toLowerCase()}-toggle-container`
    },
    switchId() {
      return `${this.code.toLowerCase()}-toggle-switch`
    },
    radioId() {
      return `${this.code.toLowerCase()}-toggle-radio`
    },
  },
}
</script>
