<template>
  <div
    class="flex flex-col justify-center items-center p-50 w-350 md:w-435 relative"
  >
    <button
      class="absolute right-0 top-0 pt-20 pr-20"
      :aria-label="$t('common.close_btn')"
      @click="$emit('close')"
    >
      <CloseIcon />
    </button>
    <h4 class="mt-2 font-semibold text-center w-250 leading-snug">
      {{ $t('login.learn_more.modal_title') }}
    </h4>
    <stethoscope width="60" class="mt-30" />
    <p class="text-center text-black mt-12 w-250 leading-snug">
      {{ $t('login.learn_more.use_our_app') }}
    </p>
    <phone width="60" class="mt-30" />
    <p class="text-center text-black mt-14 w-250 leading-snug">
      {{ $t('login.learn_more.earn_rewards') }}
    </p>
    <gift width="60" class="mt-30" />
    <p class="text-center text-black mt-14 w-250 leading-snug">
      {{ $t('login.learn_more.redeem_rewards') }}
    </p>
    <div class="mt-30">
      <HMButton
        size="x-small"
        class="w-300 h-40"
        rounded="full"
        fontWeight="semibold"
        @click="$emit('close')"
        >{{ $t('login.learn_more.close_btn') }}</HMButton
      >
    </div>
  </div>
</template>
<script>
import CloseIcon from '@components/common/CloseIcon'
import { phone, gift, stethoscope } from '../../../assets/svgs'

export default {
  components: { CloseIcon, phone, gift, stethoscope },
}
</script>
