<template>
  <div
    class="flex flex-col items-center p-40 my-40 border rounded border-grey-300"
  >
    <h6 class="text-center">{{ displayCheck }}</h6>
    <p class="mt-20 text-center">{{ displayExplanation }}</p>
    <p v-if="isPostRegistrationWithMfa" class="mt-20 text-center">
      {{ displayCodeSent }}
    </p>
    <p class="mt-20 text-center">{{ $t('mfa.active') }}</p>
    <CodeInput class="mt-20" :codeError="codeError" @confirm="confirm" />
    <div v-if="showCodeSent" class="flex items-center mt-20">
      <Icon iconClass="far fa-check-circle text-green-500" />
      <span class="ml-10">{{ $t('mfa.resend_code_sent') }}</span>
    </div>
    <div v-else class="mt-20">
      {{ $t('mfa.resend_prompt') }}
      <a class="text-blue-500 underline" @click="resend">
        {{ $t('mfa.resend_code') }}
      </a>
    </div>
    <a
      v-if="isPostRegistrationWithMfa"
      class="mt-20 text-blue-500 underline"
      @click="$emit('next', 'contactInfo')"
    >
      {{ $t('registration.mfa_preference.change_contact') }}
    </a>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CodeInput from '@components/common/forms/CodeInput'
import { MFA_TYPES } from '@platform-shared/constants'
import { formatPhoneNumber } from '@platform-shared/utils/formatters'

export default {
  components: { CodeInput },
  props: {
    codeError: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showCodeSent: false,
    }
  },
  computed: {
    ...mapGetters('registration', [
      'isPostRegistrationWithMfa',
      'registrationPrimarySms',
      'registrationEmail',
      'mfaPreference',
    ]),
    ...mapGetters('member', ['mfaType']),
    mfaDisplay() {
      return this.mfaPreference || this.mfaType
    },
    displayCheck() {
      return this.mfaDisplay === MFA_TYPES.EMAIL
        ? this.$t('mfa.check_email')
        : this.$t('mfa.check_phone')
    },
    displayExplanation() {
      return this.mfaDisplay === MFA_TYPES.EMAIL
        ? this.$t('mfa.explanation_email')
        : this.$t('mfa.explanation_phone')
    },
    displayCodeSent() {
      const result =
        this.mfaDisplay === MFA_TYPES.EMAIL
          ? this.registrationEmail
          : formatPhoneNumber(this.registrationPrimarySms?.number)

      return this.$t('mfa.code_sent', [result])
    },
  },
  methods: {
    ...mapActions('member', ['requestMfaCode']),
    confirm(totp) {
      this.$emit('submitMfaCode', totp)
    },
    confirmResend() {
      this.showCodeSent = true
      setTimeout(() => {
        this.showCodeSent = false
      }, 5000)
    },
    resend() {
      this.requestMfaCode().then(this.confirmResend)
    },
  },
}
</script>
