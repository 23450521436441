import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import _map from 'lodash/map'
import {
  INVALID_CREDENTIALS,
  INVALID_CREDENTIALS_MSG,
} from '@platform-shared/constants'

const login = {
  props: {
    initialUsername: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: {
        username: this.initialUsername,
        password: '',
      },
      error: null,
    }
  },
  validations: {
    model: {
      username: {
        required,
        validUserNameLogin() {
          return !this.model.username.includes(' ')
        },
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('member', ['isLoggingIn', 'mfaType']),
    formHasError() {
      return !!this.error
    },
    showVerifyMfa() {
      return !!this.mfaType
    },
  },
  methods: {
    ...mapActions('member', ['login', 'getMemberInfo']),
    handleLogin(onSuccess) {
      if (this.isLoggingIn) return

      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.login(this.model)
        .then(() => onSuccess())
        .catch((error) => {
          this.error =
            error.json.status === 'fail'
              ? _map(error.json.data, (errorItem) => errorItem).join(
                  String.fromCharCode(0x000a)
                )
              : error.json.message === INVALID_CREDENTIALS
              ? INVALID_CREDENTIALS_MSG
              : error.json.message
        })
    },
  },
}
export default login
