import _get from 'lodash/get'
import _first from 'lodash/first'
import { mapActions, mapGetters } from 'vuex'
import { COMMUNICATION_METHODS } from '@platform-shared/constants'
import { COMMUNICATION_METHOD_CODES } from '../constants'

export default {
  props: {
    submitButtonText: {
      type: String,
      default: 'notification_settings.update_preferences',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    displayAltSubmission: {
      type: Boolean,
      default: false,
    },
    isSSORegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
      success: false,
      preferredCommunicationMethodCd: null,
      communicationMethods: COMMUNICATION_METHODS.map((commMethod) => ({
        ...commMethod,
        isAllowed: true,
      })),
    }
  },
  computed: {
    ...mapGetters('member', [
      'isLoadingNotificationSettings',
      'communicationPreferences',
      'memberInfo',
    ]),
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('registration', ['registrationInfo']),
    formData() {
      return this.communicationMethods.map((commMethod) => {
        const isPreferred =
          this.preferredCommunicationMethodCd === commMethod.code
        return {
          communicationMethodCd: commMethod.code,
          isAllowed: commMethod.isAllowed,
          isPreferred,
        }
      })
    },
    communicationMethodToggles() {
      return this.communicationMethods.filter(
        (commMethod) => commMethod.isToggle
      )
    },
    disabledCommunicationMethodCodes() {
      return this.communicationMethods
        .filter(({ isAllowed }) => !isAllowed)
        .map(({ code }) => code)
    },
    allowedCommunicationMethods() {
      return this.communicationMethods.filter(({ isAllowed }) => isAllowed)
    },
    preferredCommunicationMethod() {
      return this.communicationMethods.find(
        ({ code }) => code === this.preferredCommunicationMethodCd
      )
    },
    firstAllowedCommunicationMethod() {
      return _first(this.allowedCommunicationMethods)
    },
  },
  watch: {
    communicationMethods: {
      deep: true,
      handler() {
        this.$emit('change')

        if (!this.preferredCommunicationMethod.isAllowed) {
          const newPreferred = this.firstAllowedCommunicationMethod
            ? this.firstAllowedCommunicationMethod.code
            : null
          return this.setPreference(newPreferred)
        }
      },
    },
  },
  mounted() {
    this.communicationMethods = this.communicationMethods.filter(
      ({ requiredFeatures }) => requiredFeatures.every(this.hasFeature)
    )

    if (!this.registrationInfo.phone.cell) {
      this.communicationMethods.find(
        (method) => method.code === COMMUNICATION_METHOD_CODES.SMS
      ).isAllowed = false
    }

    this.preferredCommunicationMethodCd = _get(
      this.firstAllowedCommunicationMethod,
      'code',
      null
    )

    if (!this.isSSORegister) {
      this.communicationPreferences.forEach((commPref) => {
        const foundCommMethod = this.communicationMethods.find(
          ({ code }) => commPref.communicationMethodCd === code
        )
        if (foundCommMethod) {
          foundCommMethod.isAllowed = commPref.isAllowed
        }
        if (commPref.isPreferred) {
          this.preferredCommunicationMethodCd = commPref.communicationMethodCd
        }
      })
    }
  },
  methods: {
    ...mapActions('member', ['createOrUpdateCommunicationPreferences']),
    setPreference(communicationMethodCd) {
      this.$emit('change')
      this.preferredCommunicationMethodCd = communicationMethodCd
    },
    submit() {
      this.$emit('submit', this.formData)
    },
  },
}
