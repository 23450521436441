<template>
  <div>
    <ContactInfoForm
      :onSubmit="handleSubmit"
      :isFetchingForMfa="isFetchingForMfa"
    />
  </div>
</template>

<script>
import ContactInfoForm from '../ContactInfoForm.vue'
import registerWithMfaMixin from '@platform-shared/mixins/registerWithMfaMixin'

export default {
  components: { ContactInfoForm },
  mixins: [registerWithMfaMixin],
}
</script>
