<template>
  <fieldset>
    <legend class="h4 mb-40">
      {{ $t('registration.communication.title') }}
    </legend>
    <div class="grid grid-cols-2 gap-4">
      <div v-for="(item, key) in filteredCommunicationMethods" :key="key">
        <input
          :id="item.code"
          class="sr-only"
          type="radio"
          name="communication-choice"
          :value="item.code"
          :disabled="item.isDisabled"
          :checked="value === item.code"
          @input="$emit('input', item.code)"
        />
        <label
          :for="item.code"
          :class="{
            'border-blue-500': value === item.code,
            'border-transparent': value !== item.code && !item.isDisabled,
            'text-grey-300 border-transparent cursor-not-allowed':
              item.isDisabled,
          }"
          class="flex items-center p-10 mx-6 my-8 rounded cursor-pointer bg-grey-100 border-2"
        >
          <Icon
            class="w-40 text-xl text-center rounded-full"
            :class="{
              'text-blue-500': !item.isDisabled,
              'text-grey-300': item.isDisabled,
            }"
            :iconClass="`fas fa-${item.icon}`"
            color="bg-blue-500"
          />
          <h5 class="pl-12 text-sm font-semibold">
            {{ $t(item.title) }}
          </h5>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { mapGetters } from 'vuex'
import { COMMUNICATION_METHODS } from '@platform-shared/constants'
import _every from 'lodash/every'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    disabledCommunicationMethodCodes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('client', ['hasFeature']),
    filteredCommunicationMethods() {
      return COMMUNICATION_METHODS.filter((commType) =>
        _every(commType.requiredFeatures, (feature) => this.hasFeature(feature))
      ).map((commMethod) => ({
        ...commMethod,
        isDisabled: this.disabledCommunicationMethodCodes.includes(
          commMethod.code
        ),
      }))
    },
  },
}
</script>
