<template>
  <div>
    <RegistrationLayoutTitles
      :title="$t('registration.rewards.title')"
      :subTitle="$t('registration.rewards.automatic_rewards')"
    />
    <div class="md:w-435 md:mx-auto">
      <GiftCardSelector
        v-for="(reward, idx) in groupedRewards"
        :key="idx"
        :reward="reward"
        :autoReward="true"
      />
      <div class="mb-12">
        <input id="enter-address" v-model="model.editAddress" type="checkbox" />
        <label class="pl-12" for="enter-address">{{
          $t('registration.rewards.edit_address')
        }}</label>
      </div>
      <Form
        v-show="model.editAddress"
        class="mb-12"
        :validator="$v.model"
        :error="error"
      >
        <div class="flex w-full mt-30">
          <Icon iconClass="fas fa-home" class="text-xl" />
          <FormInput
            v-model="model.streetAddr1"
            class="w-full pl-6"
            :label="$t('registration.contact_info.street_address_1')"
            name="streetAddr1"
            :placeholder="
              $t('registration.contact_info.street_address_1_place_holder')
            "
          />
        </div>
        <FormInput
          v-model="model.streetAddr2"
          class="mt-16 ml-auto pl-30"
          name="streetAddr2"
          :label="$t('registration.contact_info.street_address_2')"
          :placeholder="
            $t('registration.contact_info.street_address_2_place_holder')
          "
          :required="false"
        />
        <div class="flex flex-wrap py-4 mt-16 ml-auto pl-30">
          <FormInput
            v-model="model.city"
            class="w-full md:flex-1"
            name="city"
            :label="$t('registration.contact_info.city')"
            placeholder="New York"
          />
          <FormListPicker
            v-model="model.state"
            :compact="true"
            class="w-full pr-4 md:pl-8 md:pr-10 md:flex-1"
            name="state"
            :placeholder="$t('registration.contact_info.state')"
            :label="$t('registration.contact_info.state')"
            :data="states"
            autocomplete="address-level1"
          />
          <FormInput
            v-model="model.zip"
            mask="#####"
            class="w-full md:flex-1"
            name="zip"
            :label="$t('registration.contact_info.zip_code')"
            placeholder="77777"
          />
        </div>
      </Form>
      <HMButton
        id="finishButton"
        class="w-full tracking-wide"
        :disabled="!selectedAutoReward.id || (model.editAddress && formInvalid)"
        rounded="full"
        fontWeight="semibold"
        @click="handleFinish"
      >
        {{ $t('common.finish_btn') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RegistrationLayoutTitles from '@components/layouts/RegistrationLayoutTitles'
import GiftCardSelector from '@components/views/rewards/GiftCardSelector.vue'
import { STATES } from '@platform-shared/constants'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { Form, FormInput } from '../../common/forms'
import FormListPicker from '../../common/forms/FormListPicker'
import getTime from 'date-fns/getTime'
import flow from 'lodash/fp/flow'
import omit from 'lodash/fp/omit'
import mapValues from 'lodash/fp/mapValues'

export default {
  components: {
    RegistrationLayoutTitles,
    GiftCardSelector,
    Form,
    FormInput,
    FormListPicker,
  },
  data: () => ({
    states: STATES,
    model: {
      editAddress: false,
      streetAddr1: '',
      streetAddr2: '',
      zip: '',
      city: '',
      state: null,
    },
    error: null,
  }),
  validations: {
    model: {
      streetAddr1: { required },
      streetAddr2: {},
      zip: { required, minLength: minLength(5), maxLength: maxLength(5) },
      city: { required },
      state: { required },
    },
  },
  computed: {
    ...mapGetters('registration', ['registrationInfo', 'availableProducts']),
    ...mapGetters('rewards', ['selectedAutoReward', 'groupedRewards']),
    ...mapGetters('questionnaire', ['startUpQuestionnaire']),
    ...mapGetters('member', ['showPhaWelcome']),
    ...mapGetters('app', ['postLoginPath']),
    formInvalid() {
      return this.$v.$invalid
    },
  },
  mounted() {
    this.initRewards()
  },
  methods: {
    ...mapActions('registration', [
      'getAvailableProducts',
      'saveRewardAddress',
      'resetState',
    ]),
    ...mapActions('rewards', ['setupAutoReward', 'initRewards']),
    ...mapActions('app', ['removePostLoginPath']),
    handleFinish() {
      const rewardAddress = flow(
        omit(['primary', 'editAddress']),
        mapValues((value) => value || undefined)
      )(this.model.editAddress ? this.model : this.registrationInfo.address)

      const { productId, type: delivery } = this.selectedAutoReward
      this.setupAutoReward({
        productId,
        delivery,
        address: rewardAddress,
      })
        .then(() => {
          this.resetState()

          if (this.postLoginPath) {
            this.$router
              .replace(this.postLoginPath)
              .then(this.removePostLoginPath)
          } else if (this.startUpQuestionnaire && !this.showPhaWelcome) {
            localStorage.setItem('fteViewTime', getTime(new Date()))
            this.$router.push(
              `/questionnaire/${this.startUpQuestionnaire.type}`
            )
          } else {
            this.$router.replace('/')
          }
        })
        .catch((error) => {
          this.error = error.message
        })
    },
  },
}
</script>
