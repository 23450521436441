<template>
  <div>
    <CommunicationToggle
      v-for="commMethod in communicationMethodToggles"
      :key="commMethod.code"
      v-model="commMethod.isAllowed"
      class="mt-40"
      :communicationMethod="commMethod"
    />
    <CommunicationPreferenceSelector
      v-model="preferredCommunicationMethodCd"
      class="mt-60"
      :disabledCommunicationMethodCodes="disabledCommunicationMethodCodes"
    />
    <div class="mt-60 text-center flex flex-col items-center">
      <HMButton
        v-if="!displayAltSubmission"
        rounded="full"
        class="w-full tracking-wide"
        :busy="isLoadingNotificationSettings"
        @click="submit"
      >
        {{ $t(submitButtonText) }}
      </HMButton>
      <p v-if="isLoadingNotificationSettings" class="text-grey-600 mt-20">
        {{ $t('notification_settings.processing') }}
      </p>
      <FormSuccess v-if="isSuccess" class="mt-20" />
      <FormError v-else-if="isError" class="mt-20" />
    </div>
    <FormFooter
      v-if="displayAltSubmission"
      backLocation="account"
      :completeDisabled="isLoadingNotificationSettings"
      @onComplete="submit"
    />
  </div>
</template>

<script>
import CommunicationToggle from './CommunicationToggle.vue'
import { FormError, FormSuccess, FormFooter } from '@components/common/forms'
import communicationPreferencesFormMixin from '@platform-shared/mixins/communicationPreferencesForm.js'
import CommunicationPreferenceSelector from '@components/common/CommunicationPreferenceSelector'

export default {
  components: {
    CommunicationToggle,
    FormError,
    FormSuccess,
    FormFooter,
    CommunicationPreferenceSelector,
  },
  mixins: [communicationPreferencesFormMixin],
}
</script>
