<template>
  <div>
    <Spinner v-if="loadingFromLoginScreen" />
    <main v-else>
      <Login class="fixed w-screen h-screen bg-cover" />
      <div
        class="flex-grow flex flex-col relative pt-80 px-40 min-h-screen overflow-y-scroll"
      >
        <div class="flex flex-grow items-center justify-center flex-col">
          <div class="rounded bg-white p-45 w-full max-w-md">
            <div class="w-full mx-auto">
              <div>
                <div v-if="showClientLogos">
                  <img
                    class="mx-auto w-200"
                    :src="primaryLogo"
                    :alt="alt"
                    @click="handleLogoClick"
                  />
                </div>
                <div v-if="showLanguage" class="flex justify-center mt-24">
                  <LanguageSelect class="relative" floating />
                </div>
                <LoginMessage />
                <FormError
                  v-if="securityMessage"
                  class="text-white bg-red-500"
                  :text="securityMessage"
                />
                <VerifyMfa
                  v-if="showVerifyMfa"
                  :codeError="mfaCodeError"
                  @submitMfaCode="submitMfaCode"
                />
                <Form
                  v-else
                  id="loginForm"
                  :validator="$v.model"
                  :error="error"
                  @submit="handleSubmit"
                >
                  <FormInput
                    ref="username"
                    v-model="model.username"
                    :label="$t('login.username')"
                    name="username"
                    class="my-10"
                    autocomplete="username"
                  />
                  <FormInput
                    v-model="model.password"
                    type="password"
                    name="password"
                    secure
                    :label="$t('login.password')"
                    :placeholder="$t('login.password_place_holder')"
                    autocomplete="current-password"
                  />
                  <div class="px-40">
                    <HMButton
                      id="loginButton"
                      slot="button"
                      rounded="full"
                      fontWeight="semibold"
                      class="w-full"
                      :class="{ 'mt-10': formHasError, 'mt-25': !formHasError }"
                      :busy="isLoggingIn"
                    >
                      {{ $t('login.signIn_btn') }}
                    </HMButton>
                  </div>
                </Form>

                <div v-if="!showVerifyMfa" class="text-center mt-30">
                  <p class="font-bold">
                    {{ $t('login.new_here') }}
                    <router-link
                      class="ml-4 text-blue-500"
                      :to="{ name: 'register' }"
                    >
                      {{ $t('login.new_here_btn') }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div class="text-center">
                <router-link
                  class="inline-block mt-20 font-semibold text-gray-400"
                  to="/forgot-username"
                >
                  {{ $t('account_recovery.need_help.forgot_user_btn') }}
                </router-link>
                <span class="mx-5 font-semibold">|</span>
                <router-link
                  class="inline-block mt-20 font-semibold text-gray-400"
                  to="/forgot-password"
                >
                  {{ $t('account_recovery.need_help.forgot_pass_btn') }}
                </router-link>
                <br />
                <button
                  v-if="hasLearnMore"
                  class="inline-block mt-20 font-semibold text-gray-400"
                  @click="learnMoreModalOpen = true"
                >
                  {{ $t('login.learn_more.link_title') }}
                </button>
              </div>
              <Modal
                :active="learnMoreModalOpen"
                closeOnBackDrop
                @close="closeLearnMoreModal"
              >
                <LearnMoreModal @close="closeLearnMoreModal" />
              </Modal>
              <Modal :active="showDevMenu">
                <DevMenu @close="handleDevMenuClose" />
              </Modal>
            </div>
          </div>
          <div
            v-if="showClientLogos"
            class="flex flex-wrap justify-around items-center mt-20 max-w-6xl"
          >
            <img
              v-for="(logo, idx) in otherLogos"
              :key="idx"
              class="mx-24 my-12 w-200 h-auto"
              :src="logo"
              :alt="alt"
              @click="handleLogoClick"
            />
          </div>
        </div>
        <Footer :isLight="true" />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Footer from '@components/common/Footer'
import { Login } from '@assets/svgs/index'
import LanguageSelect from '@components/common/LanguageSelect'
import * as FormComponents from '@components/common/forms'
import login from '@platform-shared/mixins/login'
import getTime from 'date-fns/getTime'
import Modal from '@components/common/Modal'
import LearnMoreModal from './LearnMoreModal'
import LoginMessage from './LoginMessage'
import VerifyMfa from './VerifyMfa'
import DevMenu from '@components/common/DevMenu'
import Spinner from '@components/common/Spinner'
import { FEATURES } from '@platform-shared/constants'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

export default {
  components: {
    ...FormComponents,
    LanguageSelect,
    Footer,
    Login,
    Modal,
    LearnMoreModal,
    LoginMessage,
    DevMenu,
    VerifyMfa,
    Spinner,
  },
  mixins: [login],
  data() {
    return {
      learnMoreModalOpen: false,
      logoClickedCount: 0,
      showDevMenu: false,
      mfaCodeError: false,
    }
  },
  computed: {
    ...mapGetters('app', ['securityMessage', 'postLoginPath']),
    ...mapGetters('questionnaire', ['startUpQuestionnaire']),
    ...mapGetters('client', ['hasFeature', 'loginLogos', 'showLanguage']),
    ...mapGetters('member', [
      'showPhaWelcome',
      'loadingFromLoginScreen',
      'showSSORegistration',
    ]),
    clientLogos() {
      return _map(
        this.loginLogos,
        (logo) => `${this.$settings.S3_ROOT}/${logo}`
      )
    },
    showClientLogos() {
      return !_isEmpty(this.clientLogos)
    },
    primaryLogo() {
      return this.clientLogos[0]
    },
    otherLogos() {
      return this.clientLogos.slice(1)
    },
    alt() {
      return `${this.$settings.NAME} Logo`
    },
    hasLearnMore() {
      return this.hasFeature(FEATURES.LEARN_MORE)
    },
  },
  mounted() {
    this.setFocusEmail()
  },
  methods: {
    ...mapActions('member', ['getMemberInfo', 'verifyMfaCode']),
    ...mapActions('client', ['getClientConfig']),
    ...mapActions('app', ['removePostLoginPath']),
    memberInfoSuccess() {
      this.getClientConfig()
      if (this.showSSORegistration) {
        this.$router.replace('/register-sso')
      } else if (this.postLoginPath) {
        this.$router.replace(this.postLoginPath).then(this.removePostLoginPath)
      } else if (this.startUpQuestionnaire && !this.showPhaWelcome) {
        localStorage.setItem('fteViewTime', getTime(new Date()))
        this.$router.push(`/questionnaire/${this.startUpQuestionnaire.type}`)
      } else {
        this.$router.replace('/')
      }
    },
    initData() {
      this.getMemberInfo({ fromLoginScreen: true }).then(this.memberInfoSuccess)
    },
    submitMfaCode(totp) {
      this.mfaCodeError = false
      this.verifyMfaCode(totp)
        .then(this.initData)
        .catch(() => {
          this.mfaCodeError = true
        })
    },
    onSuccess() {
      if (!this.mfaType) {
        this.initData()
      }
    },
    handleSubmit() {
      this.$v.$touch()
      if (!this.$v.model.$invalid) {
        this.handleLogin(this.onSuccess)
      }
    },
    setFocusEmail() {
      if (this.$refs.email) {
        this.$refs.email.focus()
      }
    },
    handleLogoClick() {
      if (this.logoClickedCount === 9) {
        this.showDevMenu = true
        this.logoClickedCount = 0
      }
      this.logoClickedCount++
    },
    handleDevMenuClose() {
      this.showDevMenu = false
    },
    closeLearnMoreModal() {
      this.learnMoreModalOpen = false
    },
  },
}
</script>
