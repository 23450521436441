<template>
  <div>
    <ContactInfoForMfa v-if="display.contactInfo" @next="setDisplay" />
    <MfaPreference v-if="display.mfaPreference" @next="setDisplay" />
    <VerifyMfa
      v-if="display.verifyMfa"
      :codeError="mfaCodeError"
      @submitMfaCode="submitMfaCode"
      @next="setDisplay"
    />
  </div>
</template>

<script>
import MfaPreference from './MfaPreference.vue'
import VerifyMfa from '../../login/VerifyMfa.vue'
import ContactInfoForMfa from './ContactInfoForMfa.vue'
import { mapActions } from 'vuex'
import mapValues from 'lodash/mapValues'

export default {
  components: { MfaPreference, VerifyMfa, ContactInfoForMfa },
  data: () => ({
    display: {
      contactInfo: false,
      mfaPreference: true,
      verifyMfa: false,
    },
    mfaCodeError: false,
  }),
  computed: {},
  methods: {
    ...mapActions('member', ['verifyMfaCode']),
    setDisplay(arg) {
      this.display = mapValues(this.display, (_, key) =>
        key === arg ? true : false
      )
    },
    submitMfaCode(totp) {
      this.mfaCodeError = false
      this.verifyMfaCode(totp)
        .then(() => this.$emit('mfaComplete'))
        .catch(() => {
          this.mfaCodeError = true
        })
    },
  },
}
</script>
