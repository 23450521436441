var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full"},[_c('Form',{attrs:{"validator":_vm.$v.model,"error":_vm.error}},[_c('div',[_c('p',{staticClass:"text-sm mt-5 text-grey-600 text-center mb-10"},[_vm._v(" "+_vm._s(_vm.$t('registration.mfa_preference.explanation'))+" ")]),_c('fieldset',[_c('div',{staticClass:"grid gap-4 ml-1"},_vm._l((_vm.filteredCommMethods),function(item,key){return _c('div',{key:key},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.mfaPreference),expression:"model.mfaPreference"}],staticClass:"sr-only",attrs:{"id":item.code,"type":"radio","name":"mfa-preference-choice"},domProps:{"value":item.code,"checked":_vm.value === item.code,"checked":_vm._q(_vm.model.mfaPreference,item.code)},on:{"input":function($event){_vm.value = item.code},"change":function($event){return _vm.$set(_vm.model, "mfaPreference", item.code)}}}),_c('label',{staticClass:"flex items-center p-10 mx-6 my-8 rounded cursor-pointer border-2",class:{
                'border-blue-500': _vm.value === item.code,
                'border-grey-300': _vm.value !== item.code,
              },attrs:{"for":item.code}},[_c('Icon',{staticClass:"w-40 text-xl text-center rounded-full",class:{
                  'text-blue-500': _vm.value === item.code,
                  'text-grey-300': _vm.value !== item.code,
                },attrs:{"iconClass":`fas fa-${item.icon}`,"color":"bg-blue-500"}}),_c('h5',{staticClass:"pl-12 text-sm font-semibold"},[_vm._v(" "+_vm._s(`${_vm.$t(item.title)}: ${item.value}`)+" ")])],1)])}),0)])])]),_c('div',{staticClass:"flex flex-col w-full"},[_c('a',{staticClass:"underline text-blue-500 my-20 text-center",on:{"click":function($event){return _vm.$emit('next', 'contactInfo')}}},[_vm._v(" "+_vm._s(_vm.$t('registration.mfa_preference.change_contact'))+" ")]),_c('HMButton',{staticClass:"w-full tracking-wide",class:{ 'mt-10': _vm.formHasError, 'mt-25': !_vm.formHasError },attrs:{"id":"nextButton","rounded":"full","fontWeight":"semibold","busy":_vm.isFetching},on:{"click":_vm.handleNext}},[_vm._v(" "+_vm._s(_vm.$t('common.confirm'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }