export const fixApostrophes = (name) => {
  return name.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
}

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumber
}
