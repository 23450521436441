<template>
  <div
    v-if="message"
    class="bg-grey-100 rounded-sm px-16 py-12 mt-20 mb-10 flex"
  >
    <div class="mr-12 mt-6">
      <span
        aria-hidden="true"
        class="fa fa-exclamation text-grey-100 bg-blue-500 rounded-full w-20 h-20 flex items-center justify-center text-xxs"
      />
    </div>
    <p>
      {{ message }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('client', ['loginMessage']),
    message() {
      return this.loginMessage(this.$root.$i18n.locale)
    },
  },
}
</script>
