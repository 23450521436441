<template>
  <div class="mb-16">
    <h1 v-if="title" class="font-semibold text-center h4" v-text="title" />
    <h2
      v-if="subTitle"
      class="mt-5 font-normal text-center h5 text-grey-500"
      v-text="subTitle"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
}
</script>
