<template>
  <div class="text-center p-20 w-400">
    <h2>Dev Menu</h2>
    <HMButton class="mt-20" @click="demoResetSurveyTimestamps">
      Demo - Reset Survey Timestamps
    </HMButton>
    <HMButton class="mt-20" color="transparent" @click="$emit('close')">
      Exit
    </HMButton>
  </div>
</template>
<script>
export default {
  methods: {
    demoResetSurveyTimestamps() {
      localStorage.setItem('fteViewTime', 0)
      localStorage.setItem('hraModalViewTime', 0)
      this.$emit('close')
    },
  },
}
</script>
