<template>
  <div
    class="w-40 h-24 p-2 rounded-full flex items-center cursor-pointer"
    :class="{
      'bg-black justify-start': !value,
      'bg-blue-500 justify-end': value,
    }"
    @click="emitCheckboxChange"
  >
    <div
      class="h-20 w-20 rounded-full bg-white pill"
      :class="{ active: value }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitCheckboxChange() {
      this.$emit('input', !this.value)
    },
  },
}
</script>
<style lang="css">
.pill {
  left: 2px;
  top: calc(50% - 10px);
  transform: translateX(0);
  transition: all 300ms ease-in-out;
  transition-delay: 0ms;
}
.pill.active {
  left: calc(100% - 22px);
  transform: translateX(calc(100% - 22));
}
</style>
