<template>
  <div>
    <RegistrationLayoutTitles
      :title="$t('registration.contact_info.title', [firstName])"
      :subTitle="$t('registration.contact_info.description')"
    />
    <ContactInfoForm :onSubmit="handleSubmit" />
  </div>
</template>

<script>
import RegistrationLayoutTitles from '@components/layouts/RegistrationLayoutTitles'
import { mapActions, mapGetters } from 'vuex'
import ContactInfoForm from './ContactInfoForm.vue'

export default {
  components: {
    RegistrationLayoutTitles,
    ContactInfoForm,
  },
  props: {
    isSSORegister: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('registration', ['firstName', 'mfaPreference']),
    ...mapGetters('member', ['memberInfo']),
  },
  methods: {
    ...mapActions('registration', ['saveContactInfo', 'lookupPhoneNumbers']),
    async handleSubmit(data) {
      if (this.mfaPreference && !this.isSSORegister) {
        const numbers = []
        if (data.phone1.textValue) {
          numbers.push({
            contactNumber: data.phone1.textValue.replace(/-/g, ''),
            type: data.phone1.selectValue,
          })
        }
        if (data.phone2.textValue) {
          numbers.push({
            contactNumber: data.phone2.textValue.replace(/-/g, ''),
            type: data.phone2.selectValue,
          })
        }
        if (numbers.length > 0) {
          this.lookupPhoneNumbers(numbers)
        }
      }

      this.saveContactInfo({
        ...data,
        email: data.email,
        phone1: {
          textValue: data.phone1.textValue.replace(/-/g, ''),
          selectValue: data.phone1.selectValue,
        },
        phone2: {
          textValue: data.phone2.textValue.replace(/-/g, ''),
          selectValue: data.phone2.selectValue,
        },
      })
      this.$emit('next')
    },
  },
}
</script>