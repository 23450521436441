import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    communicationMethod: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('client', ['clientName']),
    switchValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    code() {
      return this.communicationMethod.code
    },
    icon() {
      return this.communicationMethod.icon
    },
    title() {
      return this.communicationMethod.title
    },
    body() {
      return this.communicationMethod.body
    },
    isAllowed() {
      return this.communicationMethod.isAllowed
    },
  },
}
