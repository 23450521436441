import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      isFetchingForMfa: false,
    }
  },
  computed: {
    ...mapGetters('registration', ['isPostRegistrationWithMfa']),
  },
  methods: {
    ...mapActions('registration', ['saveContactInfo', 'lookupPhoneNumbers']),
    ...mapActions('member', ['updateContactInfo']),
    async handleLookupNumbers(contactInfo) {
      const numbers = []
      let result = null
      if (contactInfo.phone1.textValue) {
        numbers.push({
          contactNumber: contactInfo.phone1.textValue.replace(/-/g, ''),
          type: contactInfo.phone1.selectValue,
        })
      }
      if (contactInfo.phone2.textValue) {
        numbers.push({
          contactNumber: contactInfo.phone2.textValue.replace(/-/g, ''),
          type: contactInfo.phone2.selectValue,
        })
      }
      if (numbers.length > 0) {
        result = await this.lookupPhoneNumbers(numbers)
      }
      return result
    },
    handlePostRegisterContactSubmit(contactInfo, lookedUpNumbers) {
      let contactSubmission = {
        email: contactInfo.email,
      }

      if (contactInfo.phone1.textValue) {
        contactSubmission[
          contactInfo.phone1.selectValue.toLowerCase()
        ] = contactInfo.phone1.textValue.replace(/-/g, '')
      }

      if (contactInfo.phone2.textValue) {
        contactSubmission[
          contactInfo.phone2.selectValue.toLowerCase()
        ] = contactInfo.phone2.textValue.replace(/-/g, '')
      }

      this.updateContactInfo({
        ...contactSubmission,
        lookedUpNumbers,
      })
        .then(() => {
          this.isFetchingForMfa = false
          this.$emit('next', 'mfaPreference')
        })
        .catch(() => (this.isFetchingForMfa = false))
    },
    async handleSubmit(contactInfo) {
      this.isFetchingForMfa = true
      const lookedUpNumbers = await this.handleLookupNumbers(contactInfo)

      this.saveContactInfo({
        ...contactInfo,
        email: contactInfo.email,
        phone1: {
          textValue: contactInfo.phone1.textValue.replace(/-/g, ''),
          selectValue: contactInfo.phone1.selectValue,
        },
        phone2: {
          textValue: contactInfo.phone2.textValue.replace(/-/g, ''),
          selectValue: contactInfo.phone2.selectValue,
        },
      })

      if (this.isPostRegistrationWithMfa) {
        this.handlePostRegisterContactSubmit(contactInfo, lookedUpNumbers)
      } else {
        this.isFetchingForMfa = false
        this.$emit('next', 'mfaPreference')
      }
    },
  },
}
