<template>
  <div class="md:w-435 md:mx-auto">
    <div class="flex flex-col items-center w-full bg-white">
      <RegistrationLayoutTitles
        :title="$t('notification_settings.title')"
        :subTitle="$t('notification_settings.description')"
      />
      <CommunicationPreferencesForm
        :isError="error"
        :isSuccess="success"
        :submitButtonText="buttonText"
        :isSSORegister="isSSORegister"
        @change="resetMessages"
        @submit="handleNext"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getTime from 'date-fns/getTime'
import RegistrationLayoutTitles from '@components/layouts/RegistrationLayoutTitles'
import CommunicationPreferencesForm from '../notification-settings/CommunicationPreferencesForm.vue'

export default {
  components: { RegistrationLayoutTitles, CommunicationPreferencesForm },
  props: {
    isSSORegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
      success: false,
    }
  },
  computed: {
    ...mapGetters('member', ['isSSOLogin', 'showPhaWelcome']),
    ...mapGetters('questionnaire', ['startUpQuestionnaire']),
    ...mapGetters('rewards', ['usesAutoRewardsInRegistration']),
    buttonText() {
      return this.isSSOLogin ? 'common.done_btn' : 'common.next_btn'
    },
  },
  methods: {
    ...mapActions('registration', [
      'saveCommunicationPreferences',
      'submitSSORegistrationInfo',
      'resetState',
    ]),

    resetMessages() {
      this.error = false
      this.success = false
    },

    async handleNext(communicationPreferences) {
      this.resetMessages()

      this.saveCommunicationPreferences(communicationPreferences).then(() => {
        if (this.isSSOLogin) {
          this.submitSSORegistrationInfo()
            .then(() => {
              if (this.usesAutoRewardsInRegistration) {
                this.$emit('next')
              } else {
                this.resetState()

                if (this.startUpQuestionnaire && !this.showPhaWelcome) {
                  localStorage.setItem('fteViewTime', getTime(new Date()))
                  this.$router.push(
                    `/questionnaire/${this.startUpQuestionnaire.type}`
                  )
                } else {
                  this.$router.replace('/')
                }
              }
            })
            .catch((error) => {
              this.error = error.message
            })
        } else {
          this.$emit('next')
        }
      })
    },
  },
}
</script>
