<template>
  <div class="flex flex-col w-full">
    <Form :validator="$v.model" :error="error">
      <div>
        <p class="text-sm mt-5 text-grey-600 text-center mb-10">
          {{ $t('registration.mfa_preference.explanation') }}
        </p>
        <fieldset>
          <div class="grid gap-4 ml-1">
            <div v-for="(item, key) in filteredCommMethods" :key="key">
              <input
                :id="item.code"
                v-model="model.mfaPreference"
                class="sr-only"
                type="radio"
                name="mfa-preference-choice"
                :value="item.code"
                :checked="value === item.code"
                @input="value = item.code"
              />
              <label
                :for="item.code"
                :class="{
                  'border-blue-500': value === item.code,
                  'border-grey-300': value !== item.code,
                }"
                class="flex items-center p-10 mx-6 my-8 rounded cursor-pointer border-2"
              >
                <Icon
                  class="w-40 text-xl text-center rounded-full"
                  :class="{
                    'text-blue-500': value === item.code,
                    'text-grey-300': value !== item.code,
                  }"
                  :iconClass="`fas fa-${item.icon}`"
                  color="bg-blue-500"
                />
                <h5 class="pl-12 text-sm font-semibold">
                  {{ `${$t(item.title)}: ${item.value}` }}
                </h5>
              </label>
            </div>
          </div>
        </fieldset>
      </div>
    </Form>
    <div class="flex flex-col w-full">
      <a
        class="underline text-blue-500 my-20 text-center"
        @click="$emit('next', 'contactInfo')"
      >
        {{ $t('registration.mfa_preference.change_contact') }}
      </a>
      <HMButton
        id="nextButton"
        rounded="full"
        fontWeight="semibold"
        class="w-full tracking-wide"
        :class="{ 'mt-10': formHasError, 'mt-25': !formHasError }"
        :busy="isFetching"
        @click="handleNext"
      >
        {{ $t('common.confirm') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import { Form } from '../../../common/forms'
import { COMMUNICATION_METHODS } from '@platform-shared/constants'
import { mapActions, mapGetters } from 'vuex'
import { MFA_TYPES } from '@platform-shared/constants'
import { formatPhoneNumber } from '@platform-shared/utils/formatters'
import sortBy from 'lodash/sortBy'

export default {
  data() {
    return {
      value: 'SMS',
      model: {
        mfaPreference: 'SMS',
      },
      error: null,
    }
  },
  validations: {
    model: {},
  },
  computed: {
    ...mapGetters('registration', [
      'registrationInfo',
      'registrationPrimarySms',
      'isPostRegistrationWithMfa',
      'isFetching',
    ]),
    formHasError() {
      return !!this.error
    },
    hasSmsCapableNumber() {
      return !!this.registrationPrimarySms.number
    },
    defaultPreferenceType() {
      return this.hasSmsCapableNumber ? 'SMS' : 'EMAIL'
    },
    filteredCommMethods() {
      const commsList = COMMUNICATION_METHODS.filter((comm) => {
        return (
          comm.code === MFA_TYPES.EMAIL ||
          (comm.code === MFA_TYPES.SMS && this.hasSmsCapableNumber)
        )
      }).map((comm) => {
        if (comm.code === MFA_TYPES.EMAIL)
          return { ...comm, value: this.registrationInfo.email }
        if (comm.code === MFA_TYPES.SMS)
          return {
            ...comm,
            value: formatPhoneNumber(this.registrationPrimarySms.number),
          }
        return comm
      })

      return sortBy(commsList, (el) => el.code !== MFA_TYPES.SMS)
    },
  },
  mounted() {
    this.value = this.defaultPreferenceType
    this.model.mfaPreference = this.defaultPreferenceType
  },
  methods: {
    ...mapActions('registration', [
      'saveMfaPreference',
      'altSubmitRegistration',
      'submitMfaPreference',
    ]),
    ...mapActions('member', ['requestMfaCode']),
    handlePreRegisterSubmit(mfaPreference) {
      this.saveMfaPreference(mfaPreference)
      this.altSubmitRegistration()
        .then(() => this.$emit('next', 'verifyMfa'))
        .catch((error) => {
          this.error = error.json.message
        })
    },
    handlePostRegisterSubmit(mfaPreference) {
      this.submitMfaPreference(mfaPreference)
        .then(() => {
          this.requestMfaCode()
          this.$emit('next', 'verifyMfa')
        })
        .catch((error) => {
          this.error = error.json.message
        })
    },
    handleNext() {
      this.error = null
      this.$v.$touch()

      if (this.$v.$invalid) return
      const model = this.model

      this.isPostRegistrationWithMfa
        ? this.handlePostRegisterSubmit(model.mfaPreference)
        : this.handlePreRegisterSubmit(model.mfaPreference)
    },
  },
}
</script>
