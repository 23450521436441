<template>
  <div class="p-20 flex flex-col max-w-sm">
    <p class="text-center">
      {{ $t('registration.member_info.confirm_lang_change', [language.name]) }}
    </p>
    <div class="flex space-x-10 justify-center mt-40">
      <HMButton outlined color="white" @click="() => $emit('cancel')">
        {{ $t('common.no') }}
      </HMButton>
      <HMButton @click="() => $emit('confirm')">
        {{ $t('common.yes') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('client', ['languages']),
    ...mapGetters('registration', ['databasePreferredLanguageCd']),
    language() {
      return this.languages.find(
        (lang) => lang.code === this.databasePreferredLanguageCd
      )
    },
  },
}
</script>
